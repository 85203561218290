import {CartType, ProductType, ShippingRuleStatus, TrackEventName} from '@wix/wixstores-client-core';
import {
  CartApi,
  CheckoutApi,
  CommandDataSetAddress,
  FullAddressCommandData,
  IPlaceOrderParams,
  PlaceOrderResponse,
  SelectedShippingOption,
  CheckoutForFastFlow,
  SiteStore,
  CartActions,
} from '@wix/wixstores-client-storefront-sdk';
import {ORIGIN as origin} from '../../components/cart/constants';
import {ICart, ILegacyCart, ICartControllerApi, ICartItem} from '../../types/app.types';
import {BIService} from './BIService';
import {StyleSettingsService} from './StyleSettingsService';
import _ from 'lodash';
import {SPECS} from '../specs';

export type CouponError = {
  code: string;
  message: string;
};

export class CartService {
  private readonly siteStore: SiteStore;
  private readonly biService: BIService;
  private readonly styleSettingsService: StyleSettingsService;
  private readonly cartApi: CartApi;
  private readonly checkoutApi: CheckoutApi;
  private readonly cartActions: CartActions;
  public couponError: CouponError = null;
  public cart: ICart;
  public checkoutId: string;

  constructor({
    siteStore,
    biService,
    styleSettingsService,
  }: {
    controllerApi: ICartControllerApi;
    siteStore: SiteStore;
    biService: BIService;
    styleSettingsService: StyleSettingsService;
  }) {
    this.siteStore = siteStore;
    this.biService = biService;
    this.styleSettingsService = styleSettingsService;
    this.cartApi = new CartApi({siteStore, origin});
    this.checkoutApi = new CheckoutApi({siteStore, origin});
    this.cartActions = new CartActions({siteStore, origin});
  }

  public async fetchCart(fetchContext: string): Promise<void> {
    const {shouldShowTax, shouldShowShipping} = this.styleSettingsService;
    this.cart = await this.cartApi.fetchCart(
      {
        locale: this.siteStore.locale,
        withShipping: shouldShowShipping,
        withTax: shouldShowTax,
      },
      fetchContext
    );
  }

  public async fetchCartWithDefaultParams(): Promise<void> {
    this.cart = await this.cartApi.fetchCart();
  }

  public async fetchCartLegacyOrCheckoutPlatform(fetchContext: string): Promise<ILegacyCart> {
    const {shouldShowTax, shouldShowShipping} = this.styleSettingsService;
    const params = {
      cartId: this.cart.cartId,
      locale: this.siteStore.locale,
      withShipping: shouldShowShipping,
      withTax: shouldShowTax,
      checkoutId: this.checkoutId,
    };

    return this.cartApi.fetchCartLegacyOrCheckout(params, fetchContext);
  }

  public async fetchCartWithTaxAndShippingIncluded(fetchContext: string): Promise<ICart> {
    return this.cartApi.fetchCart(
      {
        locale: this.siteStore.locale,
        withShipping: true,
        withTax: true,
      },
      fetchContext
    );
  }

  public get cartType(): CartType {
    const hasDigital = this.cart?.items.some((item) => item.product.productType === ProductType.DIGITAL);
    const hasPhysical = this.hasShippableItems;
    const hasService = this.cart?.items.some((item) => item.product.productType === ProductType.SERVICE);
    const hasGiftCard = this.cart?.items.some((item) => item.product.productType === ProductType.GIFT_CARD);

    if (hasDigital && hasPhysical) {
      return CartType.MIXED;
    } else if (hasDigital) {
      return CartType.DIGITAL;
    } else if (hasPhysical) {
      return CartType.PHYSICAL;
    } else if (hasService) {
      return CartType.SERVICE;
    } else if (hasGiftCard) {
      return CartType.GIFT_CARD;
    } else {
      return CartType.UNRECOGNISED;
    }
  }

  public get isNonShippableCart(): boolean {
    return this.siteStore.experiments.enabled(SPECS.ReplaceIsDigitalWithIsNonShippable)
      ? this.cartType === CartType.DIGITAL || this.cartType === CartType.SERVICE
      : this.cartType === CartType.DIGITAL;
  }

  public get hasShippableItems(): boolean {
    return this.cart?.items.some(
      (item) => !item.product.productType || item.product.productType === ProductType.PHYSICAL
    );
  }

  public get isZeroCart(): boolean {
    return this.cart.totals.total === 0;
  }

  public get isEmpty(): boolean {
    return !this.cart?.items.length;
  }

  public get areAllItemsInStock(): boolean {
    return (
      this.cart?.items &&
      this.cart.items.every((item) => _.isNull(item.inventoryQuantity) || item.inventoryQuantity > 0)
    );
  }

  public get isFullAddressRequired() {
    return this.cart.shippingRuleInfo?.status === ShippingRuleStatus.FullAddressRequired;
  }

  public get itemsCount(): number {
    return this.cart.items.reduce((count, item) => count + item.quantity, 0);
  }

  public createCheckout(): Promise<string | undefined> {
    return this.checkoutApi
      .createCheckout(this.cart.cartId)
      .then((id) => (this.checkoutId = id))
      .catch((e) => {
        console.error(e);
        return undefined;
      });
  }

  public readonly updateItemQuantity = async (
    cartItemId: number,
    quantity: number,
    productId: string
  ): Promise<void> => {
    return this.cartActions.updateLineItemQuantityInCart({
      cartId: this.cart.cartId,
      cartItemId,
      quantity,
      productId,
      itemsCount: this.cart.items.length,
      cartType: this.cartType,
    });
  };

  public readonly updateBuyerNote = async (content: string) => {
    await this.cartApi.updateBuyerNote({content}, {cart: this.cart}, false);
    this.biService.updateBuyerNote(this.cart, !!content);
  };

  public readonly removeItemFromCart = async (item: ICartItem): Promise<void> => {
    return this.cartActions.removeItemFromCart({
      cartId: this.cart.cartId,
      cartItemId: item.cartItemId,
      price: item.product.price,
      productId: item.product.id,
      productName: item.product.name,
      productType: item.product.productType,
      quantity: item.quantity,
      sku: item.sku,
      currency: this.cart.currencyFormat.code,
      catalogAppId: item.catalogAppId,
    });
  };

  public readonly trackInitiateCheckout = () => {
    const productsInfo = this.cart.items.map((item) => ({
      id: item.product.id,
      name: item.product.name,
      category: 'All Products',
      price: item.product.price,
      currency: this.siteStore.currency,
      quantity: item.quantity,
    }));

    this.siteStore.trackEvent(TrackEventName.INITIATE_CHECKOUT, {
      contents: productsInfo,
      origin: 'Stores',
    });
  };

  public readonly setShippingAddressesForFastFlow = async ({
    cartId,
    country,
    subdivision,
    zipCode,
  }: {
    cartId: string;
    country: string;
    subdivision?: string;
    zipCode?: string;
  }): Promise<CheckoutForFastFlow> => {
    return this.checkoutApi.setCartShippingAddressesForFastFlowEstimation(
      cartId,
      {country, subdivision, zipCode},
      this.checkoutId
    );
  };

  public readonly setCartAddress = async ({cartId, address}: CommandDataSetAddress): Promise<void> => {
    return this.checkoutApi.setCartAddress(cartId, address, this.checkoutId);
  };

  public readonly setCartBillingAddress = (cartId: string, address: FullAddressCommandData): Promise<void> => {
    return this.checkoutApi.setCartBillingAddress(cartId, address, this.checkoutId);
  };

  public readonly setCartShippingAddressAndDestination = (
    cartId: string,
    address: FullAddressCommandData
  ): Promise<void> => {
    return this.checkoutApi.setCartShippingAddressAndDestination(cartId, address, this.checkoutId);
  };

  public readonly placeOrder = (params: IPlaceOrderParams): Promise<PlaceOrderResponse> => {
    return this.checkoutApi.placeOrder(params);
  };

  public readonly setDestinationForEstimation = (
    {
      country,
      subdivision,
      zipCode,
    }: {
      country: string;
      subdivision?: string;
      zipCode?: string;
    },
    cartId: string
  ): Promise<void> => {
    return this.cartApi.setDestinationForEstimation({destination: {country, subdivision, zipCode}}, cartId);
  };

  public readonly setShippingOption = (
    cartId: string,
    selectedShippingOption: SelectedShippingOption
  ): Promise<void> => {
    return this.cartApi.setShippingOption(cartId, selectedShippingOption);
  };

  public get isMemberLoggedIn(): boolean {
    return !!this.siteStore.usersApi.currentUser && !!this.siteStore.usersApi.currentUser.id;
  }

  public readonly clearCouponError = (): void => {
    this.couponError = null;
  };

  public readonly applyCoupon = async (couponCode: string): Promise<void> => {
    const userIdentifier = this.siteStore.usersApi.currentUser.loggedIn
      ? await this.siteStore.usersApi.currentUser.getEmail()
      : undefined;

    await this.cartActions
      .applyCouponToCart({cartId: this.cart.cartId, couponCode, userIdentifier, isMember: this.isMemberLoggedIn})
      .catch((e) => {
        if (e.success === false) {
          const errorCode = e.errors[0].code;
          this.biService.errorWhenApplyingACouponSf(this.cart, couponCode, errorCode);
          this.couponError = {
            code: errorCode,
            message: e.errors[0].message,
          };
        }
        throw e;
      });
  };

  public readonly removeCoupon = (): Promise<void> => {
    return this.cartActions.removeCouponFromCart({
      cartId: this.cart.cartId,
      couponId: this.cart.appliedCoupon.couponId,
      couponCode: this.cart.appliedCoupon.code,
    });
  };
}
