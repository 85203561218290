export const SPECS = {
  MinimumOrderStorefront: 'specs.stores.MinimumOrderStorefront',
  CartTooltipWithoutNumber: 'specs.stores.CartTooltipWithoutNumber',
  PaypalUpdateShippingAndBilling: 'specs.stores.PaypalUpdateShippingAndBilling',
  showShippingMethodCashierExpressButton: 'specs.stores.ShowShippingMethodInApplePayModal',
  EcomPlatformCartAndCheckout: 'specs.stores.EcomPlatformCartAndCheckout',
  AddPickupPointsToCart: 'specs.stores.AddPickupPointsToCart',
  SecureCheckoutVelo: 'specs.stores.ShowHideSecureCheckoutVelo',
  PickupPointsBmPhase1: 'specs.stores.PickupPointsBmPhase1',
  MultiCurrencyPOC: 'specs.stores.MultiCurrencyPOC',
  DisplayMixedCart: 'specs.stores.DisplayMixedCart',
  UseMixedCarts: 'specs.stores.UseMixedCarts',
  UseNewCheckoutInFastFlow: 'specs.stores.UseNewCheckoutInFastFlow',
  ReplaceIsDigitalWithIsNonShippable: 'specs.stores.ReplaceIsDigitalWithIsNonShippable',
  ShouldEnableANonClickableLineItem: 'specs.stores.ShouldEnableANonClickableLineItem',
  ShouldReportIsMember: 'specs.stores.ShouldReportIsMember',
} as const;
